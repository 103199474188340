import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * A signed decimal number, which supports arbitrary precision and is serialized as a string.
   * Example values: `"29.99"`, `"29.999"`.
   */
  Decimal: any
}

export type AutoCompleteInput = {
  limit?: Maybe<Scalars['Int']>
  query: Scalars['String']
}

export type AutoCompleteResults = {
  __typename?: 'AutoCompleteResults'
  limit: Scalars['Int']
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

/** The standard ISO 4217 currency codes that represent the currencies used. */
export const CurrencyCode = {
  Cad: 'CAD',
  Usd: 'USD',
} as const

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode]

export type DocumentDescription = {
  __typename?: 'DocumentDescription'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type ExactParentOrChildSkuInput = {
  /** @deprecated(reason: "Unused") */
  limit?: Maybe<Scalars['Int']>
  sku?: Maybe<Array<Scalars['String']>>
}

export type ExactParentOrChildSkuResults = {
  __typename?: 'ExactParentOrChildSkuResults'
  /** @deprecated Unused */
  limit?: Maybe<Scalars['Int']>
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

export type FacetedInput = {
  filters?: Maybe<FacetedInputFilters>
  keywords?: Maybe<Scalars['String']>
  pagination?: Maybe<FacetedInputPagination>
  sort?: Maybe<FacetedInputSort>
}

export type FacetedInputFilters = {
  ailment?: Maybe<Array<Scalars['ID']>>
  category?: Maybe<Array<Scalars['ID']>>
  ingredient?: Maybe<Array<Scalars['ID']>>
  labelStatus?: Maybe<Array<Scalars['ID']>>
  nameRange?: Maybe<Array<Scalars['ID']>>
  productForm?: Maybe<Array<Scalars['ID']>>
  specialty?: Maybe<Array<Scalars['ID']>>
}

export type FacetedInputPagination = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type FacetedInputSort = {
  name?: Maybe<FacetedSortOrder>
}

export type FacetedResults = {
  __typename?: 'FacetedResults'
  aggregations: FacetedResultsAggregations
  keywords?: Maybe<Scalars['String']>
  meta: FacetedResultsMeta
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
}

export type FacetedResultsAggregation = {
  __typename?: 'FacetedResultsAggregation'
  id: Scalars['String']
  label?: Maybe<Scalars['String']>
  options: Array<FacetedResultsAggregationOption>
}

export type FacetedResultsAggregationOption = {
  __typename?: 'FacetedResultsAggregationOption'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  value: Scalars['ID']
}

export type FacetedResultsAggregations = {
  __typename?: 'FacetedResultsAggregations'
  ailment?: Maybe<FacetedResultsAggregation>
  category?: Maybe<FacetedResultsAggregation>
  ingredient?: Maybe<FacetedResultsAggregation>
  labelStatus?: Maybe<FacetedResultsAggregation>
  nameRange?: Maybe<FacetedResultsAggregation>
  productForm?: Maybe<FacetedResultsAggregation>
  specialty?: Maybe<FacetedResultsAggregation>
}

export type FacetedResultsMeta = {
  __typename?: 'FacetedResultsMeta'
  count: FacetedResultsMetaCount
  pagination: FacetedResultsMetaPagination
}

export type FacetedResultsMetaCount = {
  __typename?: 'FacetedResultsMetaCount'
  /** Total number of results */
  all: Scalars['Int']
  /** Number of results in current response */
  current: Scalars['Int']
}

export type FacetedResultsMetaPagination = {
  __typename?: 'FacetedResultsMetaPagination'
  currentPage: Scalars['Int']
  pageSize: Scalars['Int']
  totalPages: Scalars['Int']
}

export const FacetedSortOrder = {
  Asc: 'asc',
  Desc: 'desc',
} as const

export type FacetedSortOrder = typeof FacetedSortOrder[keyof typeof FacetedSortOrder]
/** Rich text presented as HTML or plain text. */
export type HtmlOrText = {
  __typename?: 'HtmlOrText'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type IndexedDocument = {
  id: Scalars['String']
  name: Scalars['String']
}

export type IndexedProduct = IndexedDocument & {
  __typename?: 'IndexedProduct'
  advisorTags: Array<Scalars['String']>
  ailments: Array<Scalars['String']>
  description: DocumentDescription
  id: Scalars['String']
  ingredients: Array<Scalars['String']>
  /** Maximum label status of child products */
  labelStatus?: Maybe<ProductLabelStatus>
  name: Scalars['String']
  priceRange?: Maybe<MoneyRange>
  sanityImageJson?: Maybe<Scalars['String']>
  sanityImageUrl?: Maybe<Scalars['String']>
  shortDescription: DocumentDescription
  sku: Scalars['String']
  stockStatus?: Maybe<IndexedProductStockStatus>
  /** @deprecated Use `name` */
  title: Scalars['String']
  urlKey: Scalars['String']
  variants?: Maybe<IndexedProductVariants>
}

export const IndexedProductStockStatus = {
  InStock: 'inStock',
  OutOfStock: 'outOfStock',
} as const

export type IndexedProductStockStatus = typeof IndexedProductStockStatus[keyof typeof IndexedProductStockStatus]
export const IndexedProductVariantAttribute = {
  Dosage: 'dosage',
  Flavor: 'flavor',
  FlavorSize: 'flavorSize',
  Size: 'size',
} as const

export type IndexedProductVariantAttribute = typeof IndexedProductVariantAttribute[keyof typeof IndexedProductVariantAttribute]
export type IndexedProductVariants = {
  __typename?: 'IndexedProductVariants'
  summary: IndexedProductVariantsSummary
}

export type IndexedProductVariantsSummary = {
  __typename?: 'IndexedProductVariantsSummary'
  attributes: Array<IndexedProductVariantAttribute>
  values: IndexedProductVariantsSummaryValues
}

export type IndexedProductVariantsSummaryValues = {
  __typename?: 'IndexedProductVariantsSummaryValues'
  dosage?: Maybe<Array<Scalars['String']>>
  flavor?: Maybe<Array<Scalars['String']>>
  flavorSize?: Maybe<Array<Scalars['String']>>
  size?: Maybe<Array<Scalars['String']>>
}

export type Money = {
  __typename?: 'Money'
  /** Decimal money amount. */
  amount: Scalars['Decimal']
  /** Currency of the money. */
  currencyCode: CurrencyCode
}

export type MoneyRange = {
  __typename?: 'MoneyRange'
  /** Currency of the money range. */
  currencyCode: CurrencyCode
  /** The highest price in the range. */
  maximum: Scalars['Decimal']
  /** The lowest price in the range. */
  minimum: Scalars['Decimal']
}

/** An object with an ID. */
export type Node = {
  /** ID of the object */
  id: Scalars['ID']
}

/** Possible directions in which to order a list of items */
export const OrderDirection = {
  /** Ascending */
  Asc: 'ASC',
  /** Descending */
  Desc: 'DESC',
} as const

export type OrderDirection = typeof OrderDirection[keyof typeof OrderDirection]
/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type ParentProduct = Node & {
  __typename?: 'ParentProduct'
  /** Extended product description */
  description?: Maybe<HtmlOrText>
  id: Scalars['ID']
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Products grouped under this parent product */
  products: ProductConnection
  /** Product description */
  shortDescription?: Maybe<HtmlOrText>
  sku: Scalars['String']
  urlKey: Scalars['String']
}

export type ParentProductProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProductOrdering>>
}

export type ParentProductConnection = {
  __typename?: 'ParentProductConnection'
  /** A list of edges. */
  edges: Array<ParentProductEdge>
  /** A list of nodes. */
  nodes: Array<ParentProduct>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ParentProductEdge = {
  __typename?: 'ParentProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: ParentProduct
}

/** Properties by which parent products can be ordered */
export const ParentProductOrderField = {
  /** Order by label status (maximum status of child products) */
  LabelStatus: 'LABEL_STATUS',
  /** Order by name */
  Name: 'NAME',
  /** Order by score/relevance */
  Score: 'SCORE',
} as const

export type ParentProductOrderField = typeof ParentProductOrderField[keyof typeof ParentProductOrderField]
/** Options for sorting list of parent products */
export type ParentProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: Maybe<OrderDirection>
  /** "Order by" field */
  field: ParentProductOrderField
}

/** Product (or variant) - a distinct item for sale. */
export type Product = Node & {
  __typename?: 'Product'
  id: Scalars['ID']
  /** Get label status for this product */
  labelStatus: ProductLabelStatus
  /** Main image for this product */
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Get parent product for this product */
  parentProduct: ParentProduct
  sku: Scalars['String']
}

/** Product (or variant) - a distinct item for sale. */
export type ProductLabelStatusArgs = {
  labelName: Scalars['String']
}

export type ProductConnection = {
  __typename?: 'ProductConnection'
  /** A list of edges. */
  edges: Array<ProductEdge>
  /** A list of nodes. */
  nodes: Array<Product>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Product
}

/** Label status for a given product */
export const ProductLabelStatus = {
  /** No label */
  Absent: 'ABSENT',
  /** Label present */
  Present: 'PRESENT',
} as const

export type ProductLabelStatus = typeof ProductLabelStatus[keyof typeof ProductLabelStatus]
/** Properties by which products can be ordered */
export const ProductOrderField = {
  /** Order by name */
  Name: 'NAME',
  /** Order by SKU */
  Sku: 'SKU',
} as const

export type ProductOrderField = typeof ProductOrderField[keyof typeof ProductOrderField]
/** Options for sorting list of products */
export type ProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: Maybe<OrderDirection>
  /** "Order by" field */
  field: ProductOrderField
}

/** Merged Query object */
export type Query = {
  __typename?: 'Query'
  autoComplete: AutoCompleteResults
  exact: ExactParentOrChildSkuResults
  faceted: FacetedResults
  node?: Maybe<Node>
  /** Get a parent product by SKU */
  parentProductBySku?: Maybe<ParentProduct>
  /** Get a list of parent products */
  parentProducts: ParentProductConnection
  /** Get a product by SKU */
  productBySku?: Maybe<Product>
  /** Get a list of products */
  products: ProductConnection
}

/** Merged Query object */
export type QueryAutoCompleteArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  input?: Maybe<AutoCompleteInput>
  limit?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
}

/** Merged Query object */
export type QueryExactArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  input?: Maybe<ExactParentOrChildSkuInput>
  sku?: Maybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryFacetedArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  filters?: Maybe<FacetedInputFilters>
  input?: Maybe<FacetedInput>
  keywords?: Maybe<Scalars['String']>
  orderBy?: Maybe<Array<ParentProductOrdering>>
  pagination?: Maybe<FacetedInputPagination>
}

/** Merged Query object */
export type QueryNodeArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryParentProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryParentProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  labelName?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ParentProductOrdering>>
  query?: Maybe<Scalars['String']>
  skus?: Maybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProductOrdering>>
  skus?: Maybe<Array<Scalars['String']>>
}

/** Sanity image indexed in Solr */
export type SanityImage = {
  __typename?: 'SanityImage'
  json: Scalars['String']
  url: Scalars['String']
}

export type SearchAutoCompleteQueryVariables = Exact<{
  query: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type SearchAutoCompleteQuery = { __typename?: 'Query' } & {
  autoComplete: { __typename?: 'AutoCompleteResults' } & Pick<
    AutoCompleteResults,
    'query' | 'limit' | 'suggestions' | 'totalCount'
  > & {
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          'sanityImageJson' | 'sku' | 'id' | 'name' | 'urlKey' | 'labelStatus'
        > & {
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html' | 'text'
            >
          }
      >
    }
}

export type SearchExactParentOrChildSkuQueryVariables = Exact<{
  sku?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type SearchExactParentOrChildSkuQuery = { __typename?: 'Query' } & {
  exact: { __typename?: 'ExactParentOrChildSkuResults' } & Pick<
    ExactParentOrChildSkuResults,
    'query' | 'limit' | 'suggestions' | 'totalCount'
  > & {
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          | 'advisorTags'
          | 'labelStatus'
          | 'sanityImageJson'
          | 'sanityImageUrl'
          | 'sku'
          | 'stockStatus'
          | 'id'
          | 'name'
          | 'urlKey'
        > & {
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html' | 'text'
            >
            description: { __typename?: 'DocumentDescription' } & Pick<DocumentDescription, 'html'>
            variants?: Maybe<
              { __typename?: 'IndexedProductVariants' } & {
                summary: { __typename?: 'IndexedProductVariantsSummary' } & Pick<
                  IndexedProductVariantsSummary,
                  'attributes'
                > & {
                    values: { __typename?: 'IndexedProductVariantsSummaryValues' } & Pick<
                      IndexedProductVariantsSummaryValues,
                      'size' | 'dosage' | 'flavor' | 'flavorSize'
                    >
                  }
              }
            >
            priceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
          }
      >
    }
}

export type SearchFacetedQueryVariables = Exact<{
  filters?: Maybe<FacetedInputFilters>
  keywords?: Maybe<Scalars['String']>
  orderBy?: Maybe<Array<ParentProductOrdering> | ParentProductOrdering>
  pagination?: Maybe<FacetedInputPagination>
}>

export type SearchFacetedQuery = { __typename?: 'Query' } & {
  faceted: { __typename?: 'FacetedResults' } & Pick<FacetedResults, 'suggestions'> & {
      meta: { __typename?: 'FacetedResultsMeta' } & {
        count: { __typename?: 'FacetedResultsMetaCount' } & Pick<
          FacetedResultsMetaCount,
          'all' | 'current'
        >
        pagination: { __typename?: 'FacetedResultsMetaPagination' } & Pick<
          FacetedResultsMetaPagination,
          'totalPages' | 'currentPage' | 'pageSize'
        >
      }
      aggregations: { __typename?: 'FacetedResultsAggregations' } & {
        category?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        labelStatus?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        nameRange?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        productForm?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
      }
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          | 'id'
          | 'name'
          | 'urlKey'
          | 'advisorTags'
          | 'labelStatus'
          | 'sanityImageJson'
          | 'sanityImageUrl'
          | 'sku'
          | 'stockStatus'
        > & {
            priceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html'
            >
            description: { __typename?: 'DocumentDescription' } & Pick<DocumentDescription, 'html'>
            variants?: Maybe<
              { __typename?: 'IndexedProductVariants' } & {
                summary: { __typename?: 'IndexedProductVariantsSummary' } & Pick<
                  IndexedProductVariantsSummary,
                  'attributes'
                > & {
                    values: { __typename?: 'IndexedProductVariantsSummaryValues' } & Pick<
                      IndexedProductVariantsSummaryValues,
                      'size' | 'dosage' | 'flavor' | 'flavorSize'
                    >
                  }
              }
            >
          }
      >
    }
}

export type SearchParentProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
}>

export type SearchParentProductBySkuQuery = { __typename?: 'Query' } & {
  parentProductBySku?: Maybe<
    { __typename?: 'ParentProduct' } & Pick<ParentProduct, 'id' | 'name'> & {
        products: { __typename?: 'ProductConnection' } & {
          edges: Array<
            { __typename?: 'ProductEdge' } & Pick<ProductEdge, 'cursor'> & {
                node: { __typename?: 'Product' } & Pick<
                  Product,
                  'id' | 'labelStatus' | 'name' | 'sku'
                >
              }
          >
        }
      }
  >
}

export type ProductBySkuWithLabelStatusQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
}>

export type ProductBySkuWithLabelStatusQuery = { __typename?: 'Query' } & {
  productBySku?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'id' | 'labelStatus' | 'name' | 'sku'>
  >
}

export type SearchProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
}>

export type SearchProductBySkuQuery = { __typename?: 'Query' } & {
  productBySku?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'id'> & {
        mainImage?: Maybe<{ __typename?: 'SanityImage' } & Pick<SanityImage, 'json' | 'url'>>
      }
  >
}

export const SearchAutoCompleteDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAutoComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoComplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>
export const SearchExactParentOrChildSkuDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchExactParentOrChildSku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'values' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'dosage' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavor' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavorSize' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SearchExactParentOrChildSkuQuery,
  SearchExactParentOrChildSkuQueryVariables
>
export const SearchFacetedDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchFaceted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputFilters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParentProductOrdering' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputPagination' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faceted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'all' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nameRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productForm' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dosage' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'flavor' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'flavorSize' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SearchFacetedQuery, SearchFacetedQueryVariables>
export const SearchParentProductBySkuDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchParentProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentProductBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'labelStatus' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'labelName' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'labelName' },
                                        },
                                      },
                                    ],
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SearchParentProductBySkuQuery, SearchParentProductBySkuQueryVariables>
export const ProductBySkuWithLabelStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'productBySkuWithLabelStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'labelName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ProductBySkuWithLabelStatusQuery,
  ProductBySkuWithLabelStatusQueryVariables
>
export const SearchProductBySkuDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'json' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<SearchProductBySkuQuery, SearchProductBySkuQueryVariables>
