import { state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { distinctUntilChanged, map, mergeWith } from 'rxjs'

import { latestDispensary$ } from '../dispensaries/state'

/**
 * Set current dispensary slug for use in Sanity dispensary queries.
 */
const [dispensarySlug$, setDispensarySlug] = createSignal<string | undefined>()
const latestDispensarySlug$ = state(
  latestDispensary$.pipe(
    // resolve slug from dispensary context if available
    map((dispensary) => dispensary?.slug),
    // merge with explicit dispensary slug if set
    mergeWith(dispensarySlug$),
    distinctUntilChanged()
  ),
  null
)
export { latestDispensarySlug$, setDispensarySlug }
