import { createSignal } from '@react-rxjs/utils'
import _ from 'lodash'
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeWith,
  switchMap,
} from 'rxjs'

import { latestUserCategory$ } from '../auth/state'
import { latestMagentoUserGroup$ } from '../m2/user-groups'

export interface AnalyticsEventSearch {
  event: 'search'
  search_term: string
  result_count: number
}

export interface AnalyticsEventSearchNoResults {
  event: 'search_no_results'
  search_term: string
}

export type AnalyticsItem = (
  | {
      item_id: string
      item_name?: string
    }
  | {
      item_id?: string
      item_name: string
    }
) & {
  affiliation?: string
  coupon?: string
  currency?: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price?: number
  quantity?: number
}

export interface AnalyticsEventAddToCart {
  event: 'add_to_cart'
  currency: string
  value: number
  items: AnalyticsItem[]
  app_context?: 'related_products' | 'featured_products' | 'pdp' | 'plp'
}

export interface AnalyticsEventAddEScriptToCart {
  event: 'add_escript_to_cart'
  escript_campaign: string
  escript_version: string
  escript_link: string
}

export interface AnalyticsEventAddPaymentInfo {
  event: 'add_payment_info'
  currency: string
  value: number
  coupon?: string
  payment_type?: string
  items: AnalyticsItem[]
}

export interface AnalyticsEventAddShippingInfo {
  event: 'add_shipping_info'
  currency: string
  value: number
  coupon?: string
  shipping_tier?: string
  items: AnalyticsItem[]
}

export interface AnalyticsEventBeginCheckout {
  event: 'begin_checkout'
  currency: string
  value: number
  coupon?: string
  items: AnalyticsItem[]
}

export interface AnalyticsEventCopyEScript {
  event: 'copy_escript'
  escript_campaign: string
  escript_version: string
  escript_link: string
}

export interface AnalyticsEventPurchase {
  event: 'purchase'
  currency: string
  transaction_id: string
  value: number
  affiliation?: string
  coupon?: string
  shipping?: number
  tax?: number
  items: AnalyticsItem[]
}

export interface AnalyticsEventRemoveFromCart {
  event: 'remove_from_cart'
  currency: string
  value: number
  items: AnalyticsItem[]
}

export interface AnalyticsEventViewCart {
  event: 'view_cart'
  currency: string
  value: number
  items: AnalyticsItem[]
}

export interface AnalyticsEventViewItem {
  event: 'view_item'
  currency: string
  value: number
  items: AnalyticsItem[]
  app_context: 'modal' | 'pdp'
}

export interface AnalyticsEventStartPatientOnboarding {
  event: 'start_patient_onboarding'
}

export interface AnalyticsEventSelectDispensary {
  event: 'select_dispensary'
  dispensarySlug: string
}

export type AnalyticsEvent =
  | AnalyticsEventAddToCart
  | AnalyticsEventAddEScriptToCart
  | AnalyticsEventAddPaymentInfo
  | AnalyticsEventAddShippingInfo
  | AnalyticsEventBeginCheckout
  | AnalyticsEventCopyEScript
  | AnalyticsEventPurchase
  | AnalyticsEventRemoveFromCart
  | AnalyticsEventSearch
  | AnalyticsEventSearchNoResults
  | AnalyticsEventViewCart
  | AnalyticsEventViewItem
  | AnalyticsEventStartPatientOnboarding
  | AnalyticsEventSelectDispensary

export const [analyticsEventChange$, setAnalyticsEvent] = createSignal<AnalyticsEvent>()

const searchEvent$ = analyticsEventChange$.pipe(
  filter(
    (analyticsEvent): analyticsEvent is AnalyticsEventSearch | AnalyticsEventSearchNoResults =>
      analyticsEvent.event === 'search' || analyticsEvent.event === 'search_no_results'
  ),
  debounceTime(3000)
)

const viewEvent$ = analyticsEventChange$.pipe(
  filter(
    (analyticsEvent): analyticsEvent is AnalyticsEventViewCart | AnalyticsEventViewItem =>
      analyticsEvent.event === 'view_cart' || analyticsEvent.event === 'view_item'
  ),
  distinctUntilChanged(_.isEqual)
)

const otherEvent$ = analyticsEventChange$.pipe(
  filter(
    (
      analyticsEvent
    ): analyticsEvent is Exclude<
      AnalyticsEvent,
      | AnalyticsEventSearch
      | AnalyticsEventSearchNoResults
      | AnalyticsEventViewCart
      | AnalyticsEventViewItem
    > =>
      analyticsEvent.event !== 'search' &&
      analyticsEvent.event !== 'search_no_results' &&
      analyticsEvent.event !== 'view_cart' &&
      analyticsEvent.event !== 'view_item'
  )
)

const mergedAnalyticsEvent$ = searchEvent$.pipe(mergeWith(viewEvent$, otherEvent$))

// get the latest user category and magento user group for merging into event payload
const analyticsEvent$ = combineLatest([latestUserCategory$, latestMagentoUserGroup$]).pipe(
  switchMap(([userCategory, magentoUserGroup]) =>
    // subscribe to `mergedAnalyticsEvent$` inside `switchMap` so that each event is handled once
    mergedAnalyticsEvent$.pipe(
      map((eventPayload) => ({
        ...eventPayload,
        ...(userCategory && {
          user_category: userCategory,
        }),
        ...(magentoUserGroup && {
          user_m2_group: magentoUserGroup,
        }),
      }))
    )
  )
)

// create immediate subscription to observable
if (typeof window !== 'undefined') {
  analyticsEvent$.subscribe(({ event, ...payload }) => {
    if (!window.gtag) {
      console.log(`Analytics event [${event}]:`, JSON.stringify(payload))
      return
    }
    window.gtag('event', event, payload)
  })
}
