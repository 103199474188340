import { ApolloClient } from '@apollo/client'
import { bind, state } from '@react-rxjs/core'
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  exhaustMap,
  forkJoin,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
} from 'rxjs'
import { catchError, defaultIfEmpty, filter, map } from 'rxjs/operators'

import {
  CartFragment,
  RemoveItemFromCartDocument,
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
} from '../../../../graphql/magento'
import {
  ProductBySkuWithLabelStatusDocument,
  ProductBySkuWithLabelStatusQueryVariables,
  ProductLabelStatus,
} from '../../../../graphql/search'
import { UseSiteMetadata } from '../../../../hooks/useSiteMetadata'
import { isNotNull, reduceIterable, reduceSet } from '../../../../utils/collectionTools'
import { logAndCaptureException } from '../../../../utils/errorTools'
import { coerceCaughtToLeft } from '../../../../utils/rx/errors'
import { mapRight, switchMapRight, tapLeft, tapRight } from '../../../../utils/rx/operators'
import { userClaimIds } from '../../../../utils/userClaims'
import { LabelNameNotFoundError } from '../../../auth/errors'
import { latestAccessToken$, latestAuthState$, latestLabelName$ } from '../../../auth/state'
import { latestApolloClient$ } from '../../../graphql/apollo'
import { defaultProductLabelDesignFeeSKU } from '../../config'
import { RemoveErrantItemsError } from '../../errors'
import { isVirtualCartItem, productOptionValueFromCartItem } from '../../item'
import { latestCustomerCart$ } from '../cart'
import { AutoRemoveCartItemError, CartItemSimpleProduct, CartItemVirtualProduct } from '../types'
import { cartItemsWithRetry$ } from './shared'

const checkCartItemsNeedingRemovalTick$ = new Subject<void>()

/**
 * 'Tick' the check cart items subject to prompt cart validation. Call this method when cart should be validated
 * (e.g. error occurred when adding product to cart, customer has entered checkout)
 */
export const requestCheckCartItemsNeedingRemoval = (): void =>
  checkCartItemsNeedingRemovalTick$.next()

/**
 * Query `productBySku` with `labelStatus` in selection set
 */
const productBySkuWithLabelStatusFactory = (
  client: ApolloClient<unknown>,
  token: string,
  variables: ProductBySkuWithLabelStatusQueryVariables
) =>
  from(
    client.query({
      query: ProductBySkuWithLabelStatusDocument,
      variables,
      context: {
        token,
        uri: process.env.GATSBY_SEARCH_URL,
      },
      // override global error policy
      errorPolicy: 'none',
    })
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => {
      return {
        _tag: 'Right' as const,
        data,
      }
    }),
    catchError(coerceCaughtToLeft)
  )

export interface ProductSelectionSetWithLabelStatus {
  sku: string
  labelStatus: ProductLabelStatus
}

type LabelStatusBySkuResult =
  | { _tag: 'Left'; error: LabelNameNotFoundError | Error }
  | { _tag: 'Right'; data: Map<string, ProductSelectionSetWithLabelStatus> }

/**
 * Combine latest Apollo client, access token, and user token to query label status for set of SKUs.
 *
 * Completes - does not watch query(s) for updates.
 */
const labelStatusForSkus$ = (skus: Iterable<string>): Observable<LabelStatusBySkuResult> =>
  combineLatest([
    latestApolloClient$,
    latestAccessToken$.pipe(filter(isNotNull)),
    latestAuthState$.pipe(
      map(({ user }) => user),
      filter(isNotNull)
    ),
  ]).pipe(
    take(1),
    switchMap(
      ([client, token, user]): Observable<LabelStatusBySkuResult> => {
        const labelName = user[userClaimIds.companyName]
        if (!labelName || typeof labelName !== 'string') {
          return of({ _tag: 'Left' as const, error: new LabelNameNotFoundError() })
        }

        const mapSkusToProducts = reduceIterable(
          [],
          (acc: Array<ReturnType<typeof productBySkuWithLabelStatusFactory>>, sku: string) => {
            acc.push(productBySkuWithLabelStatusFactory(client, token, { sku, labelName }))
            return acc
          }
        )
        const productsWithLabelStatus = mapSkusToProducts(skus)
        // use `forkJoin` since we expect each observable to complete
        return forkJoin(productsWithLabelStatus).pipe(
          // forkJoin completes immediately when input is an empty array
          defaultIfEmpty(undefined),
          map(
            (results): LabelStatusBySkuResult => {
              // sequence and fold array of results/eithers to a single result holding a map by sku
              return (results ?? []).reduce(
                (acc: LabelStatusBySkuResult, result) => {
                  if (acc._tag === 'Left') return acc
                  if (result._tag === 'Left') return result
                  const productBySku = result.data?.productBySku
                  // shouldn't happen
                  if (!productBySku) return acc

                  const { sku, labelStatus } = productBySku
                  acc.data.set(sku, { sku, labelStatus })
                  return acc
                },
                {
                  _tag: 'Right' as const,
                  data: new Map<string, ProductSelectionSetWithLabelStatus>(),
                }
              )
            }
          )
        )
      }
    )
  )

export type WatchLabelStatusBySku = Map<
  string,
  Observable<
    { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: ProductSelectionSetWithLabelStatus }
  >
>

/**
 * Fetch label status for given set of SKUs.
 *
 * Does not complete! Watches client, access token, label name
 */
const watchLabelStatusForSkus$ = (skus: Set<string>): Observable<WatchLabelStatusBySku> =>
  combineLatest([
    latestApolloClient$,
    latestAccessToken$.pipe(filter(isNotNull)),
    latestLabelName$.pipe(
      // error state is used elsewhere - we can focus on happy path here
      switchMap((result) => (result._tag === 'Left' ? EMPTY : of(result.data)))
    ),
  ]).pipe(
    map(
      ([client, token, labelName]): WatchLabelStatusBySku => {
        const mapSkusToProducts = reduceSet(
          new Map(),
          (acc: WatchLabelStatusBySku, sku: string) => {
            acc.set(
              sku,
              productBySkuWithLabelStatusFactory(client, token, { sku, labelName }).pipe(
                switchMapRight(({ data }) =>
                  !data.productBySku
                    ? EMPTY
                    : of({ _tag: 'Right' as const, data: data.productBySku })
                )
              )
            )
            return acc
          }
        )
        return mapSkusToProducts(skus)
      }
    )
  )

/**
 * Query cart items and return map of label status observables by SKU
 *
 * Does not complete! Watches cart items, access token, etc.
 */
export const watchCartItemsLabelStatus$: Observable<Map<
  string,
  Observable<
    { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: ProductSelectionSetWithLabelStatus }
  >
>> = cartItemsWithRetry$.pipe(
  switchMap((cartItems) => {
    // collect set of simple product skus in cart
    const simpleProductSkus = cartItems?.reduce((acc: Set<string>, item) => {
      const sku = item?.__typename === 'SimpleCartItem' ? item.product?.sku : undefined
      if (sku) {
        acc.add(sku)
      }
      return acc
    }, new Set<string>())

    return !simpleProductSkus || !simpleProductSkus.size
      ? of(new Map())
      : watchLabelStatusForSkus$(simpleProductSkus)
  })
)

/**
 * Watch label status for cart item with SKU
 */
export const [useLabelStatusForCartItemSku] = bind((sku: string) =>
  watchCartItemsLabelStatus$.pipe(
    switchMap(
      (
        statusBySku
      ): Observable<
        { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: ProductSelectionSetWithLabelStatus }
      > => {
        const status = statusBySku.get(sku)
        return !status ? EMPTY : status
      }
    )
  )
)

/**
 * Check cart items for errors related to label design fees (for PL products with `ABSENT` label status)
 */
const checkLabelDesignFees$ = (
  cartItems: NonNullable<CartFragment['items']>
): Observable<
  { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: Array<AutoRemoveCartItemError> }
> => {
  // iterate cart items and build two maps:
  // - simple product cart items by sku
  // - label design fee (virtual product) cart items by sku
  const { simpleProductsBySku, labelDesignFeeItemsBySku } = cartItems.reduce(
    (acc, item) => {
      if (item?.__typename === 'SimpleCartItem' && item.product?.sku) {
        acc.simpleProductsBySku.set(item.product.sku, item)
      } else if (isVirtualCartItem(item)) {
        const labelDesignFeeProduct = productOptionValueFromCartItem(item)
        if (item.product?.sku === defaultProductLabelDesignFeeSKU && labelDesignFeeProduct) {
          acc.labelDesignFeeItemsBySku.set(labelDesignFeeProduct, item)
        }
      }
      return acc
    },
    {
      simpleProductsBySku: new Map<string, CartItemSimpleProduct>(),
      labelDesignFeeItemsBySku: new Map<string, CartItemVirtualProduct>(),
    }
  )

  // collect set of simple product skus for which we need to check for label status
  const skusToCheck = simpleProductsBySku.keys()
  return labelStatusForSkus$(skusToCheck).pipe(
    mapRight(({ data: labelStatusBySku }) => {
      const itemsMissingLabelDesignFee: Array<AutoRemoveCartItemError> = cartItems.reduce(
        (acc: Array<AutoRemoveCartItemError>, item) => {
          // M2 increments quantity of simple product when re-adding product (instead of adding separate cart item). We
          // do not need to check for uniqueness.
          if (
            item?.__typename === 'SimpleCartItem' &&
            item.product?.sku &&
            labelStatusBySku.get(item.product.sku)?.labelStatus === ProductLabelStatus.Absent &&
            !labelDesignFeeItemsBySku.has(item.product.sku)
          ) {
            acc.push({
              error: 'ProductMissingLabelDesignFee',
              cartItem: item,
            })
          }
          return acc
        },
        []
      )

      const { errors: labelDesignFeeItemErrors } = cartItems.filter(isVirtualCartItem).reduce(
        (
          acc: {
            errors: Array<AutoRemoveCartItemError>
            labelDesignFeeItemsBySku: Map<string, CartItemVirtualProduct>
          },
          item
        ) => {
          if (item.product?.sku !== defaultProductLabelDesignFeeSKU) {
            return acc
          }

          // extract corresponding product SKU from virtual cart item options
          const labelDesignFeeProduct = productOptionValueFromCartItem(item)
          if (!labelDesignFeeProduct) {
            return acc
          }

          const simpleProduct = simpleProductsBySku.get(labelDesignFeeProduct)
          if (!simpleProduct) {
            acc.errors.push({
              error: 'LabelDesignFeeMissingProduct',
              cartItem: item,
            })
            return acc
          }

          if (
            labelStatusBySku.get(labelDesignFeeProduct)?.labelStatus !== ProductLabelStatus.Absent
          ) {
            acc.errors.push({
              error: 'LabelDesignFeeNotRequired',
              cartItem: item,
            })
            return acc
          }

          // M2 will repeat virtual cart items - identify duplicated virtual cart items and add all
          // but the last one to errors
          const existingLabelDesignFeeItem = acc.labelDesignFeeItemsBySku.get(labelDesignFeeProduct)
          if (existingLabelDesignFeeItem) {
            acc.errors.push({
              error: 'LabelDesignFeeDuplicated',
              cartItem: existingLabelDesignFeeItem,
            })
          }

          // keep track of current label design fee cart item for sku so we can keep the last one
          acc.labelDesignFeeItemsBySku.set(labelDesignFeeProduct, item)

          return acc
        },
        { errors: [], labelDesignFeeItemsBySku: new Map() }
      )
      return {
        _tag: 'Right' as const,
        data: [...itemsMissingLabelDesignFee, ...labelDesignFeeItemErrors],
      }
    })
  )
}

export const enum CartItemCheckSet {
  LABEL_DESIGN_FEES = 'LABEL_DESIGN_FEES',
}

export type CartItemErrorsResult =
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: Array<AutoRemoveCartItemError> }

/**
 * Check cart items for errors that require removal with some checks to be guarded by feature flag
 */
const cartItemErrorsForRemoval$ = (
  checkSet: CartItemCheckSet
): Observable<
  { _tag: 'Left'; error: Error } | { _tag: 'Right'; data: Array<AutoRemoveCartItemError> }
> =>
  combineLatest([checkCartItemsNeedingRemovalTick$, cartItemsWithRetry$]).pipe(
    switchMap(([_, cartItemsInput]) => {
      const cartItems = cartItemsInput ?? []
      // define array of checks to allow for easy expansion guarded by feature
      const errorChecks =
        checkSet === CartItemCheckSet.LABEL_DESIGN_FEES ? [checkLabelDesignFees$(cartItems)] : []
      return forkJoin(errorChecks).pipe(
        defaultIfEmpty(undefined),
        map(
          (results): CartItemErrorsResult => {
            // sequence and flatten array of results to a single result holding an array of errors
            return (results ?? []).reduce(
              (acc: CartItemErrorsResult, result) => {
                if (acc._tag === 'Left') return acc
                if (result._tag === 'Left') return result
                acc.data = acc.data.concat(result.data)
                return acc
              },
              {
                _tag: 'Right' as const,
                data: [],
              }
            )
          }
        )
      )
    })
  )

/**
 * Maintain subscription in top-level component to retain errors for use later.
 *
 * Error is captured before sharing result - no need to capture again.
 */
export const latestCartItemErrorsForRemoval$ = state((checkSet: CartItemCheckSet) =>
  cartItemErrorsForRemoval$(checkSet).pipe(
    tapLeft(({ error }) => {
      // capture exception once before sharing/replaying result
      logAndCaptureException(error)
    })
  )
)

/**
 * Determine check set for cart items using site's feature flags
 */
export const cartItemCheckSetFromFeatureFlags = (
  featureFlags: NonNullable<UseSiteMetadata>['featureFlags'] | undefined
): CartItemCheckSet | undefined => {
  return featureFlags?.productLabelStatus ? CartItemCheckSet.LABEL_DESIGN_FEES : undefined
}

const removeCartItemFactory = (
  client: ApolloClient<unknown>,
  token: string,
  variables: RemoveItemFromCartMutationVariables
) =>
  from(
    client.mutate({
      mutation: RemoveItemFromCartDocument,
      variables,
      context: { token },
      // override global error policy
      errorPolicy: 'none',
    })
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => ({ _tag: 'Right' as const, data })),
    catchError(coerceCaughtToLeft)
  )

/**
 * Combine latest Apollo client and access token with mutation input, taking only the first combination, and
 * return Observable for mutation.
 */
const fromRemoveCartItem = (
  variables: RemoveItemFromCartMutationVariables
): Observable<
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: RemoveItemFromCartMutation | null | undefined }
> =>
  combineLatest([latestApolloClient$, latestAccessToken$.pipe(filter(isNotNull))]).pipe(
    take(1),
    switchMap(([client, token]) => removeCartItemFactory(client, token, variables))
  )

/**
 * Remove cart items recursively, exiting early if a mutation fails
 */
const removeItemsSeq = (
  cartId: string,
  errantItems: ReadonlyArray<AutoRemoveCartItemError>
): Observable<{ _tag: 'Left'; error: Error } | { _tag: 'Right'; data: undefined }> => {
  const [head, ...tail] = errantItems
  return fromRemoveCartItem({ cartId, itemId: Number(head.cartItem.id) }).pipe(
    switchMap((result) =>
      result._tag === 'Left'
        ? of(result)
        : tail.length
        ? removeItemsSeq(cartId, tail)
        : of({ _tag: 'Right' as const, data: undefined })
    )
  )
}

export type RemoveErrantCartItemsResult =
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: ReadonlyArray<AutoRemoveCartItemError> }

export type ErrantItemsAutomaticallyRemoved = Map<number, ReadonlyArray<AutoRemoveCartItemError>>

// keep track of errant items removed automatically so customer can acknowledge
const itemsAutomaticallyRemoved$ = new BehaviorSubject<ErrantItemsAutomaticallyRemoved>(
  new Map<number, ReadonlyArray<AutoRemoveCartItemError>>()
)

/**
 * Get cart items automatically removed due to error. Keyed by timestamp for acknowledgement by user.
 *
 * Hook suspends on first render since default value not specified!
 */
export const [useItemsAutomaticallyRemoved] = bind<
  ReadonlyMap<number, ReadonlyArray<AutoRemoveCartItemError>>
>(itemsAutomaticallyRemoved$)

/**
 * Acknowledge cart items automatically removed due to error
 */
export const ackAutomaticallyRemovedCartItems = (timestamp: number): void => {
  // get current map and remove timestamp
  itemsAutomaticallyRemoved$.pipe(take(1)).subscribe((itemsRemovedByTimestamp) => {
    itemsRemovedByTimestamp.delete(timestamp)
  })
}

export const ackAllAutomaticallyRemovedCartItems = (): void => {
  // get current map and remove all timestamps
  itemsAutomaticallyRemoved$.pipe(take(1)).subscribe((itemsRemovedByTimestamp) => {
    itemsRemovedByTimestamp.clear()
  })
}

const pushAutomaticallyRemovedCartItems = (
  cartItemErrors: ReadonlyArray<AutoRemoveCartItemError>
): void => {
  itemsAutomaticallyRemoved$.pipe(take(1)).subscribe((itemsRemovedByTimestamp) => {
    const now = Date.now()
    itemsRemovedByTimestamp.set(now, cartItemErrors)
  })
}

export type AutoRemoveErrantCartItemsResult =
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: undefined }

/**
 * Automatically check cart items for errors and remove errant items
 *
 * Error is captured before sharing result - no need to capture again.
 */
export const [useAutoRemoveErrantCartItems, autoRemoveErrantCartItems$] = bind(
  (
    checkSet: CartItemCheckSet | undefined
  ): Observable<{
    loading: boolean
    result: AutoRemoveErrantCartItemsResult | undefined
  }> =>
    !checkSet
      ? of({
          loading: false,
          result: undefined,
        })
      : latestCustomerCart$
          .pipe(
            // ignore error state (already captured)
            switchMap((result) => (result._tag === 'Left' ? EMPTY : of(result.data)))
          )
          .pipe(
            // exhaust map to ignore subsequent requests until current request completes
            exhaustMap((cart) =>
              latestCartItemErrorsForRemoval$(checkSet).pipe(
                // ignore error state (already captured) and return EMPTY if no errors found
                switchMap((result) =>
                  result._tag === 'Left' || !result.data.length ? EMPTY : of(result.data)
                ),
                exhaustMap((cartItemErrors) =>
                  removeItemsSeq(cart.customerCart.id, cartItemErrors).pipe(
                    tapLeft(({ error }) => {
                      logAndCaptureException(new RemoveErrantItemsError(cartItemErrors, error))
                    }),
                    tapRight(() => {
                      pushAutomaticallyRemovedCartItems(cartItemErrors)
                    }),
                    map((result) => ({
                      loading: false,
                      result:
                        result._tag === 'Left'
                          ? result
                          : { _tag: 'Right' as const, data: undefined },
                    })),
                    startWith({
                      loading: true,
                      result: undefined,
                    })
                  )
                )
              )
            )
          ),
  {
    loading: false,
    result: undefined,
  }
)
