import { makeStyles } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import React, { FC, useCallback, useMemo, useState } from 'react'

import { ToastContext, ToastId } from '../lib/toasts/context'

const useStyles = makeStyles(() => ({
  containerRoot: {
    zIndex: 999999999,
    '@media(min-width: 600px)': {
      maxWidth: '560px',
    },
  },
}))

const ToastContextProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastId[]>(() => [])
  const [dismissed, setDismissed] = useState<ToastId[]>(() => [])

  const isToastShown = useCallback((toastId: ToastId) => toasts.includes(toastId), [toasts])

  const isToastDismissed = useCallback((toastId: ToastId) => dismissed.includes(toastId), [
    dismissed,
  ])

  // use single hook to memoize actions without deps
  const actions = useMemo(
    () => ({
      showToast: (toastId: ToastId) => {
        setToasts((currentToasts) => {
          if (currentToasts.includes(toastId)) {
            return currentToasts
          }
          return [...currentToasts, toastId]
        })
      },

      hideToast: (toastId: ToastId) => {
        setToasts((currentToasts) => {
          if (!currentToasts.includes(toastId)) {
            return currentToasts
          }
          const newToasts = [...currentToasts]
          newToasts.splice(newToasts.indexOf(toastId), 1)
          return newToasts
        })
      },

      dismissToast: (toastId: ToastId) => {
        setDismissed((currentDismissed) => {
          if (currentDismissed.includes(toastId)) {
            return currentDismissed
          }
          return [...currentDismissed, toastId]
        })
      },
    }),
    []
  )

  const classes = useStyles()

  return (
    <ToastContext.Provider
      value={{
        ...actions,
        toasts,
        dismissed,
        isToastShown,
        isToastDismissed,
      }}
    >
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{ containerRoot: classes.containerRoot }}
      >
        {children}
      </SnackbarProvider>
    </ToastContext.Provider>
  )
}

export default ToastContextProvider
