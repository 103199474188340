import {
  SanityDispensaryDocument,
  SanityDispensaryHomePage,
  SanityDispensaryLogo,
  SanityHeroImageOptionDocument,
  SanityHeroTextOptionDocument,
  SanityQueryDefinition,
  SanitySiteSettingsDocument,
} from './types'

export const siteSettingsQuery: SanityQueryDefinition<SanitySiteSettingsDocument | null> = {
  query: `
   *[_type == 'siteSettings'] | order(_createdAt desc)[0] {
      _type,
      defaultDispensaryBio,
      defaultDispensaryHeroImage->,
      defaultDispensaryHeroText->,
      defaultDispensaryProductLists,
    }
  `,
}

export const dispensaryBySlugQuery: SanityQueryDefinition<
  SanityDispensaryDocument | null,
  { slug: string }
> = {
  query: `
    *[_type == 'dispensary' && slug.current == $slug && !(_id in path('drafts.**'))] | order(_createdAt desc)[0] {
      _type,
      "slug": slug.current,
      bio,
      logo,
      homePage,
      contact,
      productLists,
    }
  `,
}

export const heroImageOptionsQuery: SanityQueryDefinition<SanityHeroImageOptionDocument[]> = {
  query: `
    *[_type == 'heroImageOption' && createdByDFH == true && image != null && textColor != null && ctaBackgroundColor != null && ctaTextColor != null && !(_id in path('drafts.**'))] | order(_updatedAt desc) {
      _type,
      _id,
      createdByDFH,
      renderEntireImageAsCta,
      slug,
      image,
      textColor,
      ctaBackgroundColor,
      ctaTextColor,
    }
  `,
}

export const heroImageOptionBySlugQuery: SanityQueryDefinition<
  SanityHeroImageOptionDocument | null,
  { slug: string }
> = {
  query: `
    *[_type == 'heroImageOption' && slug == $slug && image != null && textColor != null && ctaBackgroundColor != null && ctaTextColor != null && !(_id in path('drafts.**'))] | order(_createdAt desc)[0] {
      _type,
      _id,
      createdByDFH,
      renderEntireImageAsCta,
      slug,
      image,
      textColor,
      ctaBackgroundColor,
      ctaTextColor,
    }
  `,
}

export const heroTextOptionsQuery: SanityQueryDefinition<SanityHeroTextOptionDocument[]> = {
  query: `
    *[_type == 'heroTextOption' && createdByDFH == true && text != null && ctaText != null && !(_id in path('drafts.**'))] | order(_updatedAt desc) {
      _type,
      _id,
      createdByDFH,
      slug,
      text,
      ctaText,
    }
  `,
}

export const heroTextOptionBySlugQuery: SanityQueryDefinition<
  SanityHeroTextOptionDocument | null,
  { slug: string }
> = {
  query: `
    *[_type == 'heroTextOption' && slug == $slug && text != null && ctaText != null && !(_id in path('drafts.**'))] | order(_createdAt desc)[0] {
      _type,
      _id,
      createdByDFH,
      slug,
      text,
      ctaText,
    }
  `,
}

export const logoBySlugQuery: SanityQueryDefinition<
  SanityDispensaryLogo | null,
  { slug: string }
> = {
  query: `
    *[_type == 'dispensary' && slug.current == $slug && !(_id in path('drafts.**'))] | order(_createdAt desc)[0] {
      "logoImageUrl": logo.image.asset->url,
    }
  `,
}

export const homePageBySlugQuery: SanityQueryDefinition<
  SanityDispensaryHomePage | null,
  { slug: string }
> = {
  query: `
    *[_type == 'dispensary' && slug.current == $slug && !(_id in path('drafts.**'))] | order(_createdAt desc)[0] {
      "heroImage": homePage.heroImage->,
      "heroText": homePage.heroText->,
    }
  `,
}
