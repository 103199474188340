import { catchError, from, map, ObservableInput, of } from 'rxjs'

import { FetchError } from '../../utils/errorTools'

export * as headers from './headers'

export interface FetchLeft {
  _tag: 'Left'
  error: Error
}

export interface FetchRight<T> {
  _tag: 'Right'
  data: T
}

export type FetchResult<T> = FetchLeft | FetchRight<T>

export const fetchResponseToJsonResult = <T = unknown>(
  response: Response
): ObservableInput<FetchResult<T>> => {
  const contentType = response.headers.get('content-type') ?? ''
  const isContentTypeJson = contentType.includes('json')
  const jsonOrTextData = () =>
    from(isContentTypeJson ? response.json() : response.text()).pipe(
      catchError((err) => `Unable to parse response body: ${err}`)
    )
  if (!response.ok) {
    return jsonOrTextData().pipe(
      map((data) => ({
        _tag: 'Left' as const,
        error: new FetchError(
          `Fetch operation unsuccessful (status: ${response.status} ${response.statusText})`,
          response,
          data
        ),
      }))
    )
  }
  if (!isContentTypeJson) {
    return jsonOrTextData().pipe(
      map((data) => ({
        _tag: 'Left' as const,
        error: new FetchError(
          `Content-type unacceptable (expected: 'application/json', actual: '${contentType}')`,
          response,
          data
        ),
      }))
    )
  }
  return from(response.json()).pipe(
    map((data) => ({
      _tag: 'Right' as const,
      data,
    })),
    catchError((err) =>
      of({
        _tag: 'Left' as const,
        error: new FetchError(
          `Unable to parse response as JSON: ${err instanceof Error ? err.message : `${err}`}`,
          response
        ),
      })
    )
  )
}

export const fetchResponseToVoidResult = (
  response: Response
): ObservableInput<FetchResult<void>> => {
  const contentType = response.headers.get('content-type') ?? ''
  const isContentTypeJson = contentType.includes('json')
  const jsonOrTextData = () =>
    from(isContentTypeJson ? response.json() : response.text()).pipe(
      catchError((err) => `Unable to parse response body: ${err}`)
    )
  if (!response.ok) {
    return jsonOrTextData().pipe(
      map((data) => ({
        _tag: 'Left' as const,
        error: new FetchError(
          `Fetch operation unsuccessful (status: ${response.status} ${response.statusText})`,
          response,
          data
        ),
      }))
    )
  }
  return of({ _tag: 'Right' as const, data: undefined })
}
