import { ApolloClient } from '@apollo/client'
import { bind, state } from '@react-rxjs/core'
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  exhaustMap,
  from,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  take,
} from 'rxjs'
import { catchError, filter, map } from 'rxjs/operators'

import {
  CartFragment,
  CartItemUpdateInput,
  UpdateCartItemsDocument,
  UpdateCartItemsMutation,
  UpdateCartItemsMutationVariables,
} from '../../../../graphql/magento'
import { isNotNull } from '../../../../utils/collectionTools'
import { logAndCaptureException } from '../../../../utils/errorTools'
import { coerceCaughtToLeft } from '../../../../utils/rx/errors'
import { tapLeft, tapRight } from '../../../../utils/rx/operators'
import { latestAccessToken$ } from '../../../auth/state'
import { latestApolloClient$ } from '../../../graphql/apollo'
import { UpdateErrantItemsError } from '../../errors'
import { latestCustomerCart$ } from '../cart'
import { AutoUpdateCartItemError } from '../types'
import { cartItemsWithRetry$ } from './shared'

export const cartItemsUpdating$ = new BehaviorSubject<Array<string>>(new Array<string>())

export const [useCartItemsUpdating] = bind(cartItemsUpdating$)

export const resetCartItemsUpdating = (): void => {
  cartItemsUpdating$.next([])
}

export const setCartItemsUpdating = (cartItemSkus: Array<string>): void => {
  cartItemsUpdating$.next(cartItemSkus)
}

const checkCartItemsNeedingUpdateTick$ = new Subject<void>()

/**
 * 'Tick' the check cart items subject to prompt cart validation. Call this method when cart should be validated
 * (e.g. customer opens the cart)
 */
export const requestCheckCartItemsNeedingUpdate = (): void =>
  checkCartItemsNeedingUpdateTick$.next()

/**
 * Check cart items for issues related to their stock configurations (for products where quantity < minimum order quantity)
 */
const checkProductStockConfig$ = (
  cartItems: NonNullable<CartFragment['items']>
): Observable<Array<AutoUpdateCartItemError>> =>
  of(
    cartItems.reduce((acc: Array<AutoUpdateCartItemError>, cartItem) => {
      if (cartItem?.__typename === 'SimpleCartItem') {
        if (cartItem.quantity < Number(cartItem.product.stock_configuration?.min_sale_qty)) {
          acc.push({
            error: 'ProductQuantityBelowMinOrderQty',
            cartItem,
          })
        }
        if (cartItem.quantity > Number(cartItem.product.stock_configuration?.max_sale_qty)) {
          acc.push({
            error: 'ProductQuantityAboveMaxOrderQty',
            cartItem,
          })
        }
      }
      return acc
    }, [])
  )

/**
 * Check cart items for errors that require an update
 */
const cartItemErrorsForUpdate$ = combineLatest([
  checkCartItemsNeedingUpdateTick$,
  cartItemsWithRetry$,
]).pipe(
  switchMap(([_, cartItemsInput]) => {
    const cartItems = cartItemsInput ?? []
    return checkProductStockConfig$(cartItems)
  })
)

const updateCartItemFactory = (
  client: ApolloClient<unknown>,
  token: string,
  variables: UpdateCartItemsMutationVariables
) =>
  from(
    client.mutate({
      mutation: UpdateCartItemsDocument,
      variables,
      context: { token },
      // override global error policy
      errorPolicy: 'none',
    })
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => ({ _tag: 'Right' as const, data })),
    catchError(coerceCaughtToLeft)
  )

const fromUpdateCartItem = (
  variables: UpdateCartItemsMutationVariables
): Observable<
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: UpdateCartItemsMutation | null | undefined }
> =>
  combineLatest([latestApolloClient$, latestAccessToken$.pipe(filter(isNotNull))]).pipe(
    take(1),
    switchMap(([client, token]) => updateCartItemFactory(client, token, variables))
  )

const updateCartItems = (
  cartId: string,
  errantItems: ReadonlyArray<AutoUpdateCartItemError>
): Observable<{ _tag: 'Left'; error: Error } | { _tag: 'Right'; data: undefined }> => {
  const cartItems = errantItems.reduce((acc: Array<CartItemUpdateInput>, item) => {
    if (item.error === 'ProductQuantityBelowMinOrderQty') {
      acc.push({
        cart_item_id: Number(item.cartItem.id),
        quantity: Number(item.cartItem.product.stock_configuration?.min_sale_qty || 1),
      })
    }
    if (item.error === 'ProductQuantityAboveMaxOrderQty') {
      acc.push({
        cart_item_id: Number(item.cartItem.id),
        quantity: Number(item.cartItem.product.stock_configuration?.max_sale_qty || 1),
      })
    }
    return acc
  }, [])
  return fromUpdateCartItem({ cartId, cartItems }).pipe(
    switchMap((result) =>
      result._tag === 'Left' ? of(result) : of({ _tag: 'Right' as const, data: undefined })
    )
  )
}

export type ErrantItemsAutomaticallyUpdated = Map<string, AutoUpdateCartItemError>

export const itemsAutomaticallyUpdated$ = new BehaviorSubject<ErrantItemsAutomaticallyUpdated>(
  new Map<string, AutoUpdateCartItemError>()
)

/**
 * Get cart items automatically updated due to error. Keyed by cart item id for acknowledgement by user.
 *
 * Hook suspends on first render since default value not specified!
 */
export const [useItemsAutomaticallyUpdated] = bind<ReadonlyMap<string, AutoUpdateCartItemError>>(
  itemsAutomaticallyUpdated$
)

/**
 * Maintain subscription in top-level component to retain errors for use later.
 *
 * Error is captured before sharing result - no need to capture again.
 */
export const latestCartItemErrorsForUpdate$ = state(cartItemErrorsForUpdate$)

/**
 * Acknowledge cart items automatically updated due to error
 */
export const ackAutomaticallyUpdatedCartItem = (cartItemId: string): void => {
  itemsAutomaticallyUpdated$.pipe(take(1)).subscribe((itemsUpdatedByCartItemId) => {
    itemsUpdatedByCartItemId.delete(cartItemId)
  })
}
export const pushAutomaticallyUpdatedCartItems = (
  cartItemUpdates: ReadonlyArray<AutoUpdateCartItemError>
): void => {
  itemsAutomaticallyUpdated$.pipe(take(1)).subscribe((itemsUpdatedByCartItemId) => {
    cartItemUpdates.forEach((cartItemMessage) => {
      itemsUpdatedByCartItemId.set(cartItemMessage.cartItem.id, cartItemMessage)
    })
  })
}

export type AutoUpdateCartItemsResult =
  | { _tag: 'Left'; error: Error }
  | { _tag: 'Right'; data: undefined }

export const [useAutoUpdateErrantCartItems, autoUpdateErrantCartItems$] = bind(
  (): Observable<{
    loading: boolean
    itemsPendingUpdate: ReadonlyArray<AutoUpdateCartItemError['cartItem']>
    result: AutoUpdateCartItemsResult | undefined
  }> =>
    latestCustomerCart$
      .pipe(switchMap((result) => (result._tag === 'Left' ? EMPTY : of(result.data))))
      .pipe(
        exhaustMap((cart) =>
          latestCartItemErrorsForUpdate$.pipe(
            switchMap((result) => (!result.length ? EMPTY : of(result))),
            exhaustMap((cartItemErrors) =>
              updateCartItems(cart.customerCart.id, cartItemErrors).pipe(
                tapLeft(({ error }) => {
                  logAndCaptureException(new UpdateErrantItemsError(cartItemErrors, error))
                }),
                tapRight(() => {
                  pushAutomaticallyUpdatedCartItems(cartItemErrors)
                }),
                map((result) => ({
                  loading: false,
                  itemsPendingUpdate: [],
                  result:
                    result._tag === 'Left' ? result : { _tag: 'Right' as const, data: undefined },
                })),
                startWith({
                  loading: true,
                  itemsPendingUpdate: cartItemErrors.map((err) => err.cartItem),
                  result: undefined,
                })
              )
            )
          )
        )
      ),
  {
    loading: false,
    itemsPendingUpdate: [],
    result: undefined,
  }
)
