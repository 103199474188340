import { graphql, useStaticQuery } from 'gatsby'

import { GlobalSettingsQuery } from '../graphql/gatsby'

export type GlobalSettings = NonNullable<GlobalSettingsQuery>
export type UseGlobalSettings = GlobalSettings

export const useGlobalSettings = (): UseGlobalSettings => {
  return useStaticQuery<GlobalSettingsQuery>(
    graphql`
      query globalSettings {
        sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
          title
          lang
          description
          keywords
          ...eftForm
          ...taxIdForm
          ...commissionsOptOutForm
          ...siteLogo
          ...checkoutHelpText
          ...defaultHeroTag
          ...frontPage
          ...acceptedCreditCards
          ...freeShippingThresholds
          ...shippingTooltips
          ...mainMenuFragment
          ...utilityMenuFragment
          ...mobileMenuFragment
          ...footerCompanyMenuFragment
          ...footerHelpMenuFragment
          ...footerLegalFootnotesFragment
          ...footerLegalMenuFragment
          ...footerQuestionMenuFragment
          ...footerResourcesMenuFragment
          ...footerSocialMenuFragment
          ...helpCenterMenuFragment
          ...noSearchResultsFound
          ...returnPolicyText
          ...returnsHelpText
          ...dispensaryUpdateCartErrorMessage
          ...datasheetDisclaimerFragment
          ...dispensaryBannerTextDisclaimer
          ...blockedUserErrorMessage
          ...onboardingPatientSpotlight
        }
        site {
          siteMetadata {
            title
            description
            author
            commissionsNotificationDaysToHide
            commissionsNotificationUseModal
            commissionsNotificationToastOpensModal
            featureFlags {
              anonymousCart
              autoShipAndSave
              autoShipConfirmation
              commissionsOptOut
              disableInvalidDispensaries
              hideRewardsOrders
              manageDispensary
              manageDispensingOptions
              manageEScriptBuilder
              manageInboundPatientReferral
              manageRebateProgram
              manageWellWorld
              manageGenomicsSpotlight
              practitionerDropShip
              practitionerFormsReminder
              productLabelStatus
            }
            googleAdsEventsByCategory {
              patient
              practitioner
              employee
              other_professional
            }
            legalAgreements
            manageWellWorldEntryPoint
            manageGenomicsSpotlightEntryPoint
            productionSiteBaseUrl
            registerTypeDefault
            shippingRestrictions
            testKitProducts {
              sku
            }
          }
        }
        allSanityShippingCountry {
          nodes {
            country
            isShippingRestricted
            shippingRegions {
              ... on SanityShippingRegionAu {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionCa {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionCh {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionCn {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionGb {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionNz {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionSg {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
              ... on SanityShippingRegionUs {
                isProductsRestrictedForAllRegions
                region
                restrictedProducts {
                  sku
                }
              }
            }
          }
        }
      }
    `
  )
}
